/* eslint-disable react/jsx-props-no-spreading */
import { Box, Image, List, ListIcon, ListItem, Text } from "@chakra-ui/react";
import React from "react";
import { MdCheckCircle } from "react-icons/md";
import { IQuestion } from "./data";

interface QuestionItemProps {
  question: IQuestion;
}

const QuestionItem = (props: QuestionItemProps) => {
  const { question } = props;

  return (
    <Box mt="4">
      <Text fontWeight="bold" my="1">
        {`Câu ${question.questionNumber}: ${question.title}`}
      </Text>

      {question.image && (
        <Box my="2">
          <Image src={question.image} alt={question.title} w="full" />
        </Box>
      )}

      {question.answers.length && (
        <List spacing="1">
          {question.answers.map((answer, index) => {
            return (
              <ListItem key={answer.item}>
                <ListIcon
                  {...(answer.isValid ? { as: MdCheckCircle } : {})}
                  color="green.500"
                />
                {`${index + 1}. ${answer.item}`}
              </ListItem>
            );
          })}
        </List>
      )}
    </Box>
  );
};

export default QuestionItem;
