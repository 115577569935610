import { Box } from "@chakra-ui/react";
import Questions from "components/600Questions";
import React from "react";

const App = () => {
  return (
    <Box p="4">
      <Questions />
    </Box>
  );
};

export default App;
