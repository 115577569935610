import { useEffect, useState } from "react";

/**
 * useDebounce hook
 * @param value value need to be delay after specific time
 * @param delay time to delay value is returned
 * @returns debounceValue after the expected time delay
 */
export const useDebounce = <T>(value: T, delay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};
