import { extendTheme } from "@chakra-ui/react";
import { containerCustom } from "./containerCustom";

const mainFont = "'Poppins', serif";

export const theme = extendTheme({
  styles: {
    global: {
      "html, body, #root": {
        height: "full",
      },
    },
  },
  colors: {
    primary: "#e0f2d7",
    secondary: "#4ad539",
  },
  fonts: {
    body: mainFont,
    heading: mainFont,
    mono: mainFont,
  },
  components: {
    ...containerCustom,
  },
});
