import { Box, Heading, Input, Text } from "@chakra-ui/react";
import { useDebounce } from "hooks/useDebound";
import React, { useMemo, useState } from "react";
import { IQuestion, questionsData } from "./data";
import QuestionItem from "./QuestionItem";

const Questions = () => {
  const [keyword, setKeyword] = useState("");
  const keywordDebounce = useDebounce(keyword, 500);

  const questionDisplay = useMemo(() => {
    const keywordFormatted = keywordDebounce.trim().toLowerCase();

    return questionsData.filter((question: IQuestion) => {
      const isMatchTitle = question.title
        .toLowerCase()
        .includes(keywordFormatted);

      return isMatchTitle;
    });
  }, [keywordDebounce]);

  return (
    <Box>
      <Heading mb="4" textAlign="center">
        My App
        <Text fontSize="sm" fontWeight="semibold">
          v1.0.0
        </Text>
      </Heading>

      <Input
        placeholder="Enter the question..."
        onChange={(e) => setKeyword(e.target.value)}
      />

      <Text mt="2">Length: {questionDisplay.length}</Text>

      {questionDisplay.length ? (
        <>
          {questionDisplay.map((item: IQuestion) => {
            return <QuestionItem key={item.title} question={item} />;
          })}
        </>
      ) : (
        <Box mt="4">
          <Text textAlign="center" color="red.400">
            Nothing match with your keyword. Please try with other words
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default Questions;
